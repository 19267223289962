import type { TuningOption } from "./types";

export const TABLATURE_MAX_EVENTS = 5000;
export const MAX_STRINGS = 20;
export const MAX_STRING_LENGTH = 100;
export const MAX_NOTEBOOKS = 10;
export const MAX_LABEL_LENGTH = 30;
export const TABLATURE_PREVIEW_SIZE = 20;
export const EXTRA_ROWS_IN_TABLATURE = 1;
export const EDITOR_TABLATURE_EXTRA_HEIGHT = 256;
export const MAX_SONGS = 500;

/** The maximum version of dtos supported */
export const MAX_SUPPORTED_SONG_DTO_VERSION = 1;
export const CURRENT_SONG_DTO_VERSION = 1;

export enum LoadingStatus {
    UNINITIALIZED,
    LOADING,
    RELOADING,
    READY,
    ERROR,
}

export enum SyncStatus {
    LOGGED_OUT,
    OFFLINE,
    IN_PROGRESS,
    DONE,
    ERROR,
    NOT_SYNCED,
}

export const DEFAULT_TUNINGS = {
    guitar: ["E", "B", "G", "D", "A", "E"] as TuningOption[],
    ukulele: [] as TuningOption[],
    bass: ["E", "B", "G", "D"] as TuningOption[],
    banjo: [] as TuningOption[],
};

export type INSTRUMENT_OPTIONS = { tuning: TuningOption[]; strings: number };

// Note: Tuning goes from top string to bottom (first)
export const DEFAULT_INSTRUMENT_OPTIONS: Record<SongInstrument, INSTRUMENT_OPTIONS> = {
    guitar: { tuning: ["E", "B", "G", "D", "A", "E"], strings: 6 },
    ukulele: { tuning: ["A", "E", "C", "G"], strings: 4 },
    bass: { tuning: ["G", "D", "A", "E"], strings: 4 },
    banjo: { tuning: ["D", "B", "G", "D", "G"], strings: 5 },
    bass5: { tuning: ["G", "D", "A", "E", "B"], strings: 5 },
    guitar7: { tuning: ["E", "B", "G", "D", "A", "E", "B"], strings: 7 },
};

export const DEFAULT_TUNING: TuningOption[] = ["E", "B", "G", "D", "A", "E"];

export const TUNING_OPTIONS: TuningOption[] = ["E", "F", "F#", "G", "G#", "A", "A#", "B", "C", "C#", "D", "D#"];

export const SONG_INSTRUMENTS = ["guitar", "guitar7", "bass", "bass5", "ukulele", "banjo"] as const;

export type SongInstrument = (typeof SONG_INSTRUMENTS)[number];

export const DEFAULT_INSTRUMENT = "guitar";
